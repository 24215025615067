import React from 'react'

import Layout from 'components/Layout'
import { Container } from 'components/Container'
import { DonatationsClosed } from 'components/DonationsClosed'
import { Donors } from 'components/Donors'

const DonatePage = () => {
  return (
    <Layout>
      <Container>
        <DonatationsClosed />
        <Donors />
      </Container>
    </Layout>
  )
}

export default DonatePage
export { Head } from 'components/Head'
