import React from 'react'
import { Container } from 'components/Container'
import { FlagIcon } from '@heroicons/react/solid'

export const DonatationsClosed = () => {
  return (
    <section id="hero" aria-label="" className="relative sm:my-10 text-center">
      <Container className="relative">
        <p className="text-sm text-gray-600">
          <FlagIcon className="inline h-5 w-5 text-red-800" /> We have stopped
          accepting donations for the campaign. Thank you to everyone that
          donated!
        </p>
      </Container>
    </section>
  )
}
